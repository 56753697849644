import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo('en-US');

export const formatDateTime = (date: string | Date) => {
  return Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
  }).format(new Date(date));
};

const toLocal = (date: string | Date) => {
  let dateLocal = new Date(date);
  let newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const formatDate = (date: string | Date, options?: Intl.DateTimeFormatOptions) => {
  const dateOptions = { ...options, dateStyle: 'medium' };
  return Intl.DateTimeFormat('en-US', {
    ...options,
  }).format(new Date(date));
};

export const toLocalTimeAgo = (date: string | Date) => {
  return timeAgo.format(toLocal(date));
};

export const toTimeAgo = (date: string | Date, options?: { short?: boolean }) => {
  const ago = timeAgo.format(new Date(date));
  if (options?.short) {
    return ago
      .replace('minutes', 'm')
      .replace('minute', 'm')
      .replace('hours', 'h')
      .replace('hour', 'h')
      .replace('days', 'd')
      .replace('day', 'd')
      .replace('weeks', 'wk')
      .replace('week', 'wk')
      .replace('months', 'mo')
      .replace('month', 'mo')
      .replace('years', 'yr')
      .replace('year', 'yr');
  }
  return ago;
};

export const secondsToTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

export const durationAsTime = (start?: Date | string | null | undefined, end?: Date | string | null | undefined) => {
  if (!start || !end) return '...';
  const duration = new Date(end).getTime() - new Date(start).getTime();
  return secondsToTime(Math.floor(duration / 1000));
};
